import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    return (
        <div className='privacy-policy'>
            <div className='container'>
                <h1>Privacy Policy</h1>
                <p>At Samara~ A Wellness Hotel by Bharat Thakur, we are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us through our website and any related services.</p>
                <h2>Information We Collect</h2>
                <p>- Personal Information: We may collect personal information such as your name, email address, phone number, and billing details when you make a reservation or interact with our website.</p>
                <p>- Usage Information: We may collect information about how you navigate our website, including your IP address, browser type, device information, and pages visited.</p>
                <p>- Cookies: We use cookies and similar technologies to enhance your browsing experience and gather information about your preferences and activities on our website.</p>
                <h2>How We Use Your Information:</h2>
                <p>- Personalization: We use the information we collect to personalize your experience on our website, respond to your inquiries, and provide you with relevant offers and promotions.</p>
                <p>- Reservation Processing: We use your personal information to process and manage your reservations, including communicating with you about your booking and sending confirmation emails.</p>
                <p>- Analytics: We may use usage information and cookies to analyze website traffic, track user interactions, and improve the functionality and performance of our website.</p>
                <h2>Information Sharing and Disclosure:</h2>
                <p>- Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website, processing payments, and delivering services to you.</p>
                <p>- Legal Compliance: We may disclose your information when required by law, court order, or legal process, or to protect the rights, property, or safety of Samara Wellness Hotel, our guests, or others.</p>
                <h2>Data Security:</h2>
                <p>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                <h2>Your Privacy Choices:</h2>
                <p>You have the right to access, update, or delete your personal information by contacting us using the information provided below. You may also opt-out of receiving promotional communications from us by following the instructions included in such communications.</p>
                <h2>Changes to this Privacy Policy:</h2>
                <p>We reserve the right to update or revise this Privacy Policy at any time without prior notice. We encourage you to review this page periodically for any changes. Your continued use of our website after any modifications to this Privacy Policy will constitute your acceptance of the updated terms.</p>
                <h2>Contact Us:</h2>
                <p>If you have any questions, concerns, or feedback regarding this Privacy Policy or our privacy practices, please contact us at:</p>
                <p><a href="mailto:Samarawellnesshotel@gmail.com">Samarawellnesshotel@gmail.com</a> <br/>
                House No. 354 Survey No. 120/2k & 130/2L Morjim, 403519 North Goa.</p>
            </div>
        </div>
    )
}

export default PrivacyPolicy