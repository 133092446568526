import React, { useState } from 'react'
import Logo from '../componentgallery/logo_white.png';
import { Link, useLocation } from 'react-router-dom';
import './navbar.css';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseSharp } from "react-icons/io5";
const Navbar = () => {
  const [open, setOpen] = useState(false);
  const MenuOpen = () => {
    setOpen(!open);
  }
  const location = useLocation();
  const navCLass = location.pathname === '/faq' || location.pathname === '/privacy-policy' || location.pathname === '/terms-of-use' ? 'dark-bg' : '';

  return (
    <>
      <div className={`navbar ${navCLass}`}>
        <div className='logo'>
          <img src={Logo} alt="" width='100%' />
        </div>
        <div className='items'>
          <ul>
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/wellness'>Wellness</Link></li>
            <li><Link to='/room'>Rooms</Link></li>
            <li><Link to='/gallery'>Gallery/Experience</Link></li>
            <li><Link to='/about'>About Samara</Link></li>
            <li><Link to='/event'>Event</Link></li>
            <li><Link to='/dining'>Dining</Link></li>
            {/* <li>Shop</li> */}
          </ul>
        </div>
        <div className='last_navbar_item'>
          <div className='button_last'>
            <Link to='https://live2.mycloudhospitality.com/mycloud_IRS2.0/Hotel/Home//v6H0jdtW$K1gS3W2Os3NWw==' target="_blank">BOOK YOUR STAY &rarr;</Link>
          </div>
          <div className='menu_icon' onClick={MenuOpen}>
            <p>{open ? <IoCloseSharp /> :
              <RxHamburgerMenu />}</p>
          </div>
        </div>
      </div>
      <div className={open ? 'mobile_menu' : 'close_menu'}>
        <div className='item_mobile'>
          <div className='blur'>
          </div>
          <div className='item_list'>
            <div className='menu_icon close_icon' onClick={MenuOpen}>
              <p>{open ? <IoCloseSharp /> :
                <RxHamburgerMenu />}</p>
            </div>
            <ul>
              <Link to='/' onClick={MenuOpen}><li>Home</li></Link>
              <Link to='/wellness' ><li>Wellness </li></Link>
              <Link to='/room' onClick={MenuOpen}><li>Rooms</li></Link>
              <Link to='/gallery' onClick={MenuOpen}><li>Gallery/Experience</li></Link>
              <Link to='/about' onClick={MenuOpen}><li>About Samara</li></Link>
              <Link to='/event' onClick={MenuOpen}><li>Event</li></Link>
              <Link to='/dining' onClick={MenuOpen}><li>Dining</li></Link>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar