import React from 'react'
import './loading.css';
const Loading = () => {
  return (
    <>
    <div className="loading-circle-container">
      <div className="loading-circle"></div>
    </div>
    </>
  )
}

export default Loading