import React from 'react'
import Collarge from '../../structure/collarge/Collarge';
import banner from '../pages_images/gallery_banner.png'
import gv1 from './images/gal_ver (1).JPG';
import gv2 from './images/gal_ver (2).JPG';
import gv3 from './images/gal_ver (3).JPG';
import gh1 from './images/gal_hor (1).JPG';
import gh2 from './images/gal_hor (2).JPG';
import gh3 from './images/gal_hor (3).JPG';
import gv4 from './images/gal_ver (4).JPG';
import gv5 from './images/gal_ver (5).JPG';
import gv6 from './images/gal_ver (6).JPG';
import gh4 from './images/gal_hor (4).JPG';
import gh5 from './images/gal_hor (5).JPG';
import gh6 from './images/gal_hor (6).JPG';
import gv7 from './images/gal_ver (7).JPG';
import gv8 from './images/gal_ver (8).JPG';
import gv9 from './images/gal_ver (9).jpeg';
import gh7 from './images/gal_hor (7).JPG';
import gh8 from './images/gal_hor (8).JPG';
import gh9 from './images/gal_hor (9).JPG';

import './gallery.css';

const Gallery = () => {
    const data = {
        pic1: gh1,
        pic2: gv1,
        pic3: gv2,
        pic4: gh2,
        pic5: gh3,
        pic6: gv3,
        pic7: gv4,
        pic8: gh4,
    }
    const data2 = {
        pic1: gh4,
        pic2: gv4,
        pic3: gv5,
        pic4: gh5,
        pic5: gh6,
        pic6: gv6,
        pic7: gv7,
        pic8: gh7,
    }
    const data3 = {
        pic1: gh7,
        pic2: gv7,
        pic3: gv8,
        pic4: gh8,
        pic5: gh9,
        pic6: gv9,
        pic7: gv1,
        pic8: gh1
    }
    return (
        <>
            <div>
                <div>
                    <img src={banner} alt="" width='100%' />
                </div>
                <div className='gallery_part1'>
                    <div><Collarge pics={data} /></div>
                    <div><Collarge pics={data2} /></div>
                    <div><Collarge pics={data3} /></div>
                </div>

            </div>
        </>
    )
}

export default Gallery