import './aboutstructure.css';

const AboutStructure2 = ({ data }) => {
    return (
        <>
            <div className="about_Mainhead about_Mainhead2"><p>{data.Mainhead}</p></div>
            <div className="about_head about_head2"><p dangerouslySetInnerHTML={{ __html: data.head }} /></div>
            <div className="about_structure_wrap about_structure2">
                <div className="image_about"><img src={data.img} alt="" width='100%' height='100%' /></div>
                <div className="about_content_struture">
                    <div className="about_para about_para2"><p >{data.para}</p></div>
                </div>
            </div>
        </>
    )
}

export default AboutStructure2