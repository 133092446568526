import './structureroompage.css';
import banner from '../../pages/pages_images/Rooms_banner.png';
import { FaCheck } from "react-icons/fa";
import pic1 from './swimming_pool.png';
import pic2 from './power_backup.png';
import pic3 from './24_hour_room_service.png';
import { Link } from "react-router-dom"
const Structureroompage = ({ data,children }) => {
    return (
        <>
            <div>
                <img src={banner} alt="" width='100%' />
            </div>
            <div className='rooms_pages_container'>
                <div className='rooms_pages_images'>
                    <div className='rooms_pages_sub_image'>
                        <div className='rooms_pages_sub1'><img src={data.pic4} alt="" width='100%' height='100%'/></div>
                        <div className='rooms_pages_sub2'><img src={data.pic5} alt="" width='100%' height='100%'/></div>
                    </div>
                    <div className='rooms_pages_main_image'>
                        <img src={data.pic1} alt="" width='100%' height='100%' />
                    </div>
                    <div className='rooms_pages_sub_image'>
                        <div className='rooms_pages_sub1'><img src={data.pic2} alt="" width='100%' height='100%'/></div>
                        <div className='rooms_pages_sub2'><img src={data.pic3} alt="" width='100%' height='100%'/></div>
                    </div>
                </div>
                <div className='rooms_pages_contents'>
                    <div className='map_rooms_pages'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d984962.9467283923!2d73.34722614574983!3d15.348759664324652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfba106336b741%3A0xeaf887ff62f34092!2sGoa!5e0!3m2!1sen!2sin!4v1704755648412!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'></iframe>
                    </div>
                    <div className='rooms_pages_contents_container'>
                        <div className='rooms_pages_head'><p>{data.head}</p></div>
                        <div className='rooms_pages_subcontent'>
                            <div className='part1_content_rooms_pages'>
                                {/* <div className='content_rooms_pages_p'><p>For 2 Adults</p></div>
                                <div className='content_rooms_pages_p'><p></p></div>
                                <div className='content_rooms_pages_p'><p>Free Cancellation till check-in</p></div>
                                <div className='content_rooms_pages_p'><p></p></div> */}
                                {children}
                            </div>
                            <div className='part2_content_rooms_pages'>
                                {data.price ? <div className='content_rooms_pages_price'><p>Rs:- {data.price}/- </p></div> : null}
                                <div className='content_rooms_pages_p'><p> +12% GST </p></div>
                                <div className='content_rooms_pages_p'><p>Per Night</p></div>
                            </div>
                        </div>
                        <div><hr className='line_rooms_pages' /></div>
                        <div className='button_room_pages'><p><Link to='https://live2.mycloudhospitality.com/mycloud_IRS2.0/Hotel/Home//v6H0jdtW$K1gS3W2Os3NWw==' target="_blank">Book Now</Link></p></div>
                    </div>
                </div>
            </div>
            <div className='details_rooms_pages'>
                <div className='details_rooms_pages_container'>
                    <div className='details_head_rooms_pages'><p>Samara Wellness Hotel</p></div>
                    <div className='details_sub_head_rooms_pages'><p>{data.head}</p></div>
                    <div className='details_parahead_rooms_pages'><p>Amenities at Samara Wellness Hotel</p></div>
                    <div className='details_Amenities_container'>
                        <div className='Amenities_part_container'>
                            <div className='Amenities_icon'><img src={pic1} alt="" width='100%' /></div>
                            <div className='Amenities_name'><p>Swimming Pool</p></div>
                        </div>
                        <div className='Amenities_part_container'>
                            <div className='Amenities_icon'><img src={pic2} alt="" width='100%' /></div>
                            <div className='Amenities_name'><p>24-hour Room Service</p></div>
                        </div>
                        <div className='Amenities_part_container'>
                            <div className='Amenities_icon icons_react'><FaCheck /></div>
                            <div className='Amenities_name'><p>Smoking Rooms</p></div>
                        </div>
                        <div className='Amenities_part_container'>
                            <div className='Amenities_icon'><img src={pic3} alt="" width='100%' /></div>
                            <div className='Amenities_name'><p>Power Backup</p></div>
                        </div>
                    </div>
                    <div><hr className='line_rooms_pages' /></div>
                    <div className='highlights_benefits'>
                        <div className='hightlights_benefits_container'>
                            <div className='hightlights_benefits_head'><p>Highlighted Amenities</p></div>
                            <ul className='list_hightlights_benefits'>
                                <li>Swimming Pool</li>
                                <li>24-hour Room Service</li>
                                <li>Smoking Rooms</li>
                                <li>Power Backup</li>
                            </ul>
                        </div>
                        <div className='hightlights_benefits_container'>
                            <div className='hightlights_benefits_head'><p>Basic Facilities</p></div>
                            <ul className='list_hightlights_benefits'>
                                <li>Laundry Service</li>
                                <li>Free Wi-Fi</li>
                                <li>Air Conditioning</li>
                                <li>Housekeeping</li>
                                <li>Free Parking</li>
                                <li>Elevator/Lift</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Structureroompage