import Structureroompage from "../../structure/roompagestructure/Structureroompage"
import Pic1 from './images/la1 (1).jpeg';
import Pic2 from './images/la1 (2).jpeg';
import Pic3 from './images/la1 (3).jpeg';
import Pic4 from './images/la1 (4).jpeg';
import Pic5 from './images/la1 (5).jpeg';
const Lilac = () => {
    const data={
        pic1:Pic3,
        pic2:Pic4,
        pic3:Pic2,
        pic4:Pic1,
        pic5:Pic5,
        head:'Lilac Suite',
        price:'7499',
    }
  return (
    <>
    <div>
        {/* <Structureroompage data={data}/> */}
        <Structureroompage data={data}>
        <div className='content_rooms_pages_p'><p>For 2 Adults</p></div>
        <div className='content_rooms_pages_p'><p>Room with Breakfast</p></div>
        <div className='content_rooms_pages_p'><p>Additional bed charge: 1500 + tax</p></div>
        <div className='content_rooms_pages_p'><p>Free Cancellation till check-in</p></div>
        </Structureroompage>
    </div>
    </>
  )
}

export default Lilac