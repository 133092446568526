import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import Loading from "../component/loading/Loading";
const UserContext = createContext();
const OtpContext = createContext();
const CarpetContext = createContext();
const DeluxeContext = createContext();
const LuxuryContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function useOtp() {
  return useContext(OtpContext);
}

export function useCarpet() {
  return useContext(CarpetContext);
}

export function useDeluxe() {
  return useContext(DeluxeContext);
}

export function useLuxury() {
  return useContext(LuxuryContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  const [initializing, setInitializing] = useState(true);
  const login = (userData) => {
    // Implement login logic and set user state here
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const logout = () => {
    // Implement logout logic here
    localStorage.removeItem("user");
    setUser(null);
  };
  const initializeUser = async () => {
    // Simulate an async task such as fetching user data from a server
    // You should replace this with actual async logic
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    // After initialization, set initializing to false
    setInitializing(false);
  };

  // Call the initialization function only once
  if (initializing) {
    initializeUser();
  }

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {initializing ? (
        <div>
          <Loading />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
}

export function OtpProvider({ children }) {
  const [otp, setOtp] = useState(null);

  const [initializing, setInitializing] = useState(true);

  const SaveOtp = (OtpData) => {
    // Implement login logic and set user state here
    setOtp(OtpData);
  };

  const RemoveOtp = () => {
    // Implement logout logic here
    setOtp(null);
  };
  const initializeUser = async () => {
    // Simulate an async task such as fetching user data from a server
    // You should replace this with actual async logic
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const storedOtp = localStorage.getItem("otp");
    if (storedOtp) {
      setOtp(JSON.parse(storedOtp));
    }
    // After initialization, set initializing to false
    setInitializing(false);
  };

  // Call the initialization function only once
  if (initializing) {
    initializeUser();
  }

  return (
    <OtpContext.Provider value={{ otp, SaveOtp, RemoveOtp }}>
      {initializing ? (
        <div>
          <Loading />
        </div>
      ) : (
        children
      )}
    </OtpContext.Provider>
  );
}

export function CarpetProvider({ children }) {
  const [products, setProducts] = useState([]);
  const secrets = "youraregood"; // Product data stat
  const product_views = () => {
    const payloads = {
      iss: "ecommerce",
      aud: "yourname",
    };
    const headers = {
      alg: "HS256",
    };
    const encodeHeader = btoa(JSON.stringify(headers));
    const payloadString = btoa(JSON.stringify(payloads));
    const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secrets).toString(CryptoJS.enc.Base64);
    const jwt = `${encodeHeader}.${payloadString}.${signature}`;
    console.log(jwt);
    axios
      .get("https://api.shreeaadyapicture.com/wayout.php", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        setProducts(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    product_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Aur baki product management functions yahan add karein

  return <CarpetContext.Provider value={{ products, setProducts }}>{children}</CarpetContext.Provider>;
}

export function DeluxeProvider({ children }) {
  const [products, setProducts] = useState([]);
  const secrets = "youraregood"; // Product data stat
  const product_views = () => {
    const payloads = {
      iss: "ecommerce",
      aud: "yourname",
    };
    const headers = {
      alg: "HS256",
    };
    const encodeHeader = btoa(JSON.stringify(headers));
    const payloadString = btoa(JSON.stringify(payloads));
    const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secrets).toString(CryptoJS.enc.Base64);
    const jwt = `${encodeHeader}.${payloadString}.${signature}`;
    console.log(jwt);
    axios
      .get("https://api.shreeaadyapicture.com/wayout.php", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        setProducts(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    product_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Aur baki product management functions yahan add karein

  return <DeluxeContext.Provider value={{ products, setProducts }}>{children}</DeluxeContext.Provider>;
}

export function LuxuryProvider({ children }) {
  const [products, setProducts] = useState([]);
  const secrets = "youraregood"; // Product data stat
  const product_views = () => {
    const payloads = {
      iss: "ecommerce",
      aud: "yourname",
    };
    const headers = {
      alg: "HS256",
    };
    const encodeHeader = btoa(JSON.stringify(headers));
    const payloadString = btoa(JSON.stringify(payloads));
    const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secrets).toString(CryptoJS.enc.Base64);
    const jwt = `${encodeHeader}.${payloadString}.${signature}`;
    console.log(jwt);
    axios
      .get("https://api.shreeaadyapicture.com/wayout.php", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        setProducts(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    product_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Aur baki product management functions yahan add karein

  return <LuxuryContext.Provider value={{ products, setProducts }}>{children}</LuxuryContext.Provider>;
}
