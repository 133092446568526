import './structure.css';
import { Link } from "react-router-dom"

const Structure2 = ({ data }) => {
    return (
        <>
            <div className='structure_wrap'>

                <div className='structure_part1 structure2_part1'>
                    <div className='structure_button_part1'>
                        <p><Link to='https://live2.mycloudhospitality.com/mycloud_IRS2.0/Hotel/Home//v6H0jdtW$K1gS3W2Os3NWw==' target="_blank">BOOK NOW &rarr;</Link>  </p>
                    </div>
                    <div className='structure_poster structure2_poster'>
                        <img src={data.poster} alt="" width='100%' height='100%'/>
                    </div>
                </div>

                <div className='space_structure'></div>
                <div className='box_structure box_structure2'>
                    <div className='box_structure_container'>
                        <div className='box_image'>
                            <img src={data.boxImg} alt="" width='100%' />
                        </div>
                        <div className='box_content'>
                            <div className='box_head'><p>{data.head}</p></div>
                            <div className='box_para'><p>{data.para}</p></div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Structure2