import React from 'react'
import Collarge from '../../structure/collarge/Collarge';
import Banner from '../pages_images/bannerpic (4).png';
import gv1 from './images/event_ver (1).JPG';
import gv2 from './images/event_ver (2).JPG';
import gv3 from './images/event_ver (3).JPG';
import gh1 from './images/event_ho (1).JPG';
import gh2 from './images/event_ho (2).JPG';
import gh3 from './images/event_ho (3).JPG';
import gv4 from './images/event_ver (4).JPG';
import gv5 from './images/event_ver (5).JPG';
import gv6 from './images/event_ver (6).JPG';
import gh4 from './images/event_ho (4).JPG';
import gh5 from './images/event_ho (5).JPG';
import gh6 from './images/event_ho (6).JPG';
import gv7 from './images/event_ver (7).JPG';
import gv8 from './images/event_ver (8).JPG';
import gv9 from './images/event_ver (9).JPG';
import gh7 from './images/event_ho (7).JPG';
import gh8 from './images/event_ho (8).JPG';
import gh9 from './images/event_ho (9).JPG';
import gv10 from './images/event_ver (10).JPG';
import gv11 from './images/event_ver (11).JPG';
import gv12 from './images/event_ver (12).JPG';
import gh10 from './images/event_ho (10).JPG';
import gh11 from './images/event_ho (11).JPG';
import gh12 from './images/event_ho (12).PNG';

import './event.css';

const Gallery = () => {
    const data = {
        pic1: gh1,
        pic2: gv1,
        pic3: gv2,
        pic4: gh2,
        pic5: gh3,
        pic6: gv3,
        pic7: gv10,
        pic8: gh10,
    }
    const data2 = {
        pic1: gh4,
        pic2: gv4,
        pic3: gv5,
        pic4: gh5,
        pic5: gh6,
        pic6: gv6,
        pic7: gv11,
        pic8: gh11,
    }
    const data3 = {
        pic1: gh7,
        pic2: gv7,
        pic3: gv8,
        pic4: gh8,
        pic5: gh9,
        pic6: gv9,
        pic7: gv12,
        pic8: gh12
    }
    return (
        <>
            <div>
                <div>
                    <img src={Banner} alt="" width='100%' />
                </div>
                <div className='gallery_part1'>
                    <div><Collarge pics={data} /></div>
                    <div><Collarge pics={data2} /></div>
                    <div><Collarge pics={data3} /></div>
                </div>

            </div>
        </>
    )
}

export default Gallery