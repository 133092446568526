import Structureroompage from "../../structure/roompagestructure/Structureroompage"
import Pic1 from './images/executive (1).JPG';
import Pic2 from './images/executive (2).JPG';
import Pic3 from './images/executive (3).JPG';
import Pic4 from './images/executive (4).JPG';
import Pic5 from './images/executive (5).JPG';
const Executive = () => {
    const data={
        pic1:Pic1,
        pic2:Pic2,
        pic3:Pic3,
        pic4:Pic4,
        pic5:Pic5,
        head:'Executive Room',
        price:'5499', 
    }
  return (
    <>
    <div>
        {/* <Structureroompage data={data}/> */}
        <Structureroompage data={data}>
        <div className='content_rooms_pages_p'><p>For 2 Adults</p></div>
        <div className='content_rooms_pages_p'><p>Room with Breakfast</p></div>
        <div className='content_rooms_pages_p'><p>Additional bed charge: 1500 + tax</p></div>
        <div className='content_rooms_pages_p'><p>Free Cancellation till check-in</p></div>
        </Structureroompage>
    </div>
    </>
  )
}

export default Executive