import Banner from '../home/naya (2).png';
import Structure1 from '../../structure/roomStructure/Structure1';
import Structure2 from '../../structure/roomStructure/Structure2';
import pic4 from '../home_change (1).jpeg';
import pic5 from '../pages_images/Image (1).png';
import pic7 from '../home_change (2).jpeg';
import pic8 from '../pages_images/Image (2).png';
import './wellness.css';
const Wellness = () => {

  const data1 = {
    poster: pic4,
    boxImg: pic5,
    head: 'THE SPA “Heal Yourself”',
    para: 'Our committed and highly skilled Artistic Yoga teachers are available to practice yoga. Our most special offering, the Kalpa Vedic Baths, is a process to be experienced. Your skin is an organ through one can both detox and nourish ourselves. ',
  }

  const data3 = {
    poster: pic7,
    boxImg: pic8,
    head: 'THE EXPERIENCE “Happiness is a state of mind” ',
    para: 'Whether you enjoy swimming in our crystal clear pool. Or soaking in the Kalpa Vedic Baths. Or relishing a flavourful meal at the Mystique Spice Restaurant. Or just enjoying a drink at the Sundowner… Our vision is simple - immerse in the richness of life at Samara and leave feeling calmer, younger, with a new zest for life.',
  }

  return (
    <>
      <div>
        <div><img src={Banner} alt="" width='100%' /></div>
      </div>
      <div className='wellness_container_wrap_bg'>
        <Structure1 data={data1} />
        <Structure2 data={data3} />
      </div>
    </>
  )
}

export default Wellness