import './collarge.css';

const Collarge = ({pics}) => {
    return (
        <>
            <div className="collarge">
                <div className="collarge_container">
                    <div className="collarge_image"><img src={pics.pic1} alt="" width='100%' height='100%'/></div>
                    <div className="collarge_image"><img src={pics.pic2} alt="" width='100%' height='100%'/></div>
                </div>
                <div className="collarge_container">
                    <div className="collarge_image"><img src={pics.pic3} alt="" width='100%' height='100%'/></div>
                    <div className="collarge_image"><img src={pics.pic4} alt="" width='100%' height='100%'/></div>
                </div>
                <div className="collarge_container">
                    <div className="collarge_image"><img src={pics.pic5} alt="" width='100%' height='100%'/></div>
                    <div className="collarge_image"><img src={pics.pic6} alt="" width='100%' height='100%'/></div>
                </div>
            </div>
            <div className="collarge_mobile">
                <div className="collarge_container">
                    <div className="collarge_image"><img src={pics.pic1} alt="" width='100%' height='100%'/></div>
                    <div className="collarge_image"><img src={pics.pic2} alt="" width='100%' height='100%'/></div>
                </div>
                <div className="collarge_container">
                    <div className="collarge_image"><img src={pics.pic3} alt="" width='100%' height='100%'/></div>
                    <div className="collarge_image"><img src={pics.pic4} alt="" width='100%' height='100%'/></div>
                </div>
            </div>
            <div className="collarge_mobile">
                <div className="collarge_container">
                    <div className="collarge_image"><img src={pics.pic5} alt="" width='100%' height='100%'/></div>
                    <div className="collarge_image"><img src={pics.pic6} alt="" width='100%' height='100%'/></div>
                </div>
                <div className="collarge_container">
                    <div className="collarge_image"><img src={pics.pic7} alt="" width='100%' height='100%'/></div>
                    <div className="collarge_image"><img src={pics.pic8} alt="" width='100%' height='100%'/></div>
                </div>
            </div>
        </>
    )
}

export default Collarge