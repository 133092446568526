import Structureroompage from "../../structure/roompagestructure/Structureroompage"
import pic1 from './images/ch (1).JPG';
import pic2 from './images/ch (2).JPG';
import pic3 from './images/ch (3).JPG';
import pic4 from './images/ch (4).JPG';
import pic5 from './images/ch (5).JPG';
const Chestnut = () => {
    const data={
        pic1:pic1,
        pic2:pic2,
        pic3:pic3,
        pic4:pic4,
        pic5:pic5,
        head:'Chestnut Suite',
        price:'7499', 
    }
  return (
    <>
    <div>
        {/* <Structureroompage data={data}/> */}
        <Structureroompage data={data}>
        <div className='content_rooms_pages_p'><p>For 2 Adults</p></div>
        <div className='content_rooms_pages_p'><p>Room with Breakfast</p></div>
        <div className='content_rooms_pages_p'><p>Additional bed charge: 1500 + tax</p></div>
        <div className='content_rooms_pages_p'><p>Free Cancellation till check-in</p></div>
        </Structureroompage>
    </div>
    </>
  )
}

export default Chestnut