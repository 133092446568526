import Banner from '../pages_images/bannerpic (3).png';
import pic1 from '../pages_images/about1.PNG';
import pic2 from '../pages_images/about2.png';
import pic3 from '../pages_images/about3.png';
import AboutStructure from '../../structure/aboutstructure/AboutStructure';
import AboutStructure2 from '../../structure/aboutstructure/AboutStructure2';
import './about.css';

const About = () => {
    const data={
        Mainhead:'Samara',
        head:'“A Wellness Hotel by Bharat Thakur”',
        para:'Discover a sanctuary where wellness and art merge beautifully in the serene town of Morjim. Overlooking the tranquil Morjim turtle beach, Samara is not just a retreat, but a holistic journey with a blend of rejuvenation for the body and nourishment for the soul.',
        img:pic1,
        classname:''
    }
    const data2={
        Mainhead:'The Hotel',
        head:'“Breathing life into spaces”',
        para:"The walls of each room are adorned with artwork by Dr. Bharat Thakur. Each of the works, including sculptures and paintings are his originals.",
        img:pic2
    }
    const data3={
        Mainhead:'The Art',
        head:'“An Artist’s Vision”',
        para:"Samara is a unique space to calm your heart, heal your mind and nourish your soul just a short walk away from the sea. At Samara, enjoyment and healing go hand in hand. Enjoying a drink at the sundowner is as important as taking one of our Vedic baths!",
        img:pic3,
        classname:'head_style'
    }
    return (
        <>
            <div>
                <div><img src={Banner} alt="" width='100%' /></div>
            </div>
            <div className='aboutsamara_container_bg'>
                <AboutStructure data={data}/>
                <AboutStructure2 data={data2}/>
                <AboutStructure data={data3}/>
            </div>
        </>
    )
}

export default About