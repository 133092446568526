import "./App.css";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./component/navbar/Navbar";
import Footer from "./component/footer/Footer";
// import Login from "./pages/Auth/Login";
// import Registration from "./pages/Auth/Registration";
import { MyContextProvider } from "./statemanagement/UserContextProvider";
import { CarpetProvider, DeluxeProvider, LuxuryProvider, OtpProvider, UserProvider } from "./statemanagement/UserContext";
import Loading from "./component/loading/Loading";
// import CheckIn from "./pages/Checkin/CheckIn";
// import PrivateRoute from "./component/route/PrivateRoute";
// import Reset from "./pages/Auth/Reset";
// import Otp from "./pages/Auth/Otp";
import Gallery from "./pages/gallery/Gallery";
import Room from "./pages/Room/Room";
import Wellness from "./pages/wellness/Wellness";
import About from "./pages/about/About";
import Event from "./pages/event/Event";
import Dining from "./pages/dining/Dining";
import Chestnut from "./pages/roompages/Chestnut";
import Olive from "./pages/roompages/Olive";
import Executive from "./pages/roompages/Executive";
import Standard from "./pages/roompages/Standard";
import Lilac from "./pages/roompages/Lilac";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsCondtions from "./pages/terms-conditions/TermsCondtions";
import Faq from "./pages/faq/Faq";

const Home = lazy(() => import("./pages/home/Home"));

function App() {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <MyContextProvider>
            <UserProvider>
              <OtpProvider>
                <CarpetProvider>
                  <DeluxeProvider>
                    <LuxuryProvider>
                      <Navbar />
                      <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/home" element={<Home />} />
                        {/* <Route path="/auth" element={<PrivateRoute />}>
                          <Route exact path="checkin" element={<CheckIn />} />
                        </Route> */}
                        {/* <Route exact path="/login" element={<Login />} /> */}
                        {/* <Route exact path="/reset" element={<Reset />} /> */}
                        {/* <Route exact path="/otp" element={<Otp />} /> */}
                        {/* <Route exact path="/registration" element={<Registration />} /> */}
                        <Route exact path="/gallery" element={<Gallery />} />
                        <Route exact path="/room" element={<Room />} />
                        <Route exact path="/wellness" element={<Wellness />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="/event" element={<Event />} />
                        <Route exact path="/dining" element={<Dining />} />
                        <Route exact path="/chestnut" element={<Chestnut />} />
                        <Route exact path="/olive" element={<Olive />} />
                        <Route exact path="/executive" element={<Executive />} />
                        <Route exact path="/standard" element={<Standard />} />
                        <Route exact path="/lilac" element={<Lilac />} />
                        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route exact path="/terms-of-use" element={<TermsCondtions />} />
                        <Route exact path="/faq" element={<Faq />} />
                      </Routes>
                      <Footer />
                    </LuxuryProvider>
                  </DeluxeProvider>
                </CarpetProvider>
              </OtpProvider>
            </UserProvider>
          </MyContextProvider>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
