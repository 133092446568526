import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './faq.css'

function Faq() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className='faq-section'>
        <div className='container'>
            <h1>Samara Wellness Hotel - FAQ</h1>
            <h2>Where are we located?</h2>
            <p>Our property is just 300 metres away from Morjim beach, Goa and less than a kilometre from where the Chapora river flows into the sea with lush hills in the backdrop.</p>
            <h2>Why choose Samara?</h2>
            <p>Samara isn’t merely a stay; it's an experience. It’s where a symphony of art, gourmet cuisine, soulful music, yoga, massages and healing baths come together to give your soul a much-needed awakening. Our vision is simple - immerse in the richness of life at Samara and depart feeling younger, revitalized, and brimming with energy.</p>
            <h2>What is unique about our rooms?</h2>
            <p>Samara's 29 rooms come alive in two distinct colour palettes - beige and grey. The beige infuses the rooms with a sense of timeless sophistication, reminiscent of old-world charm. The grey, on the other hand, mirrors the modern, cutting-edge aesthetic of contemporary art and design.</p>
            <p>The rooms are bathed in soft, indirect lighting that fosters an intimate setting as night falls. Come dawn, and the ambiance undergoes a delightful transformation. The large sliding doors lead to a balcony, with abundant sunlight and idyllic views of majestic trees that sway with the rhythm of nature</p>
            <h2>What does Our Mystique Spice Restaurant & Sundowner have to offer?</h2>
            <p>Mystique Spice, a luxury restaurant perched atop the terrace of the Samara Hotel boasts a menu that transforms the act of dining into an art form, with a focus on exquisite Indian specialties. Each dish on the menu is prepared with passion and precision, where every bite becomes a revelation of taste and texture.</p>
            <p>Mystique Spice believes that dining is a sacred experience to be celebrated and deeply felt.</p>
            <p>Just one floor up is the Sundowner where you can enjoy a glass of wine or beer alongside panoramic views of the Arabian Sea and gorgeous sunsets that will warm your heart and soul.</p>
            <h2>What does our chat gully have to offer?</h2>
            <p>Enjoy the taste of authentic North Indian Chaat, the original Indian snack food that can be eaten at any time of the day! It’s the only one in Goa, where you get the real taste of Chaat – absolutely fresh, tangy and crisp, with flavours that literally burst in your mouth.</p>
            <h2>What does our Mystique Spa have to offer?</h2>
            <p>Our Spa is beautifully woven into the bath experience at Samara. Our Signature package includes an Artistic Yoga session, followed by a stretching session followed by a therapeutic massage and finally a dip in the baths to give you a 3-hour  xperience like no other. This is the Samara concept at its finest, where you are healed and rejuvenated at a cellular level!</p>
            <h2>What does our Mystique baths have to offer?</h2>
            <p>An ancient way to energise and rejuvenate, formulated as a Ten-Step process to ensure that in just an hour you are refreshed, revitalized, repaired, and tranquil. This is a one-of-a-kind experience that gives you a deep sense of harmony from within with the help of natural and powerful oils, herbs and minerals.</p>
            <h2>What are the check-in and check-out times ?</h2>
            <p>Our standard check-in time is 14:00 hrs, and check-out time is 11:00 hrs. Early check-in, late check-out, and extensions of stay are subject to availability and may incur additional charges. Children aged 5 years and above (above 3.5 feet height) will be considered as adults, and an extra adult rate plus taxes will apply.</p>
            <h2>What Airports are nearby?</h2>
            <p>Manohar International Airport– 29 Km</p>
            <p>Goa International Airport, Dabolim – 52 Km</p>
            <h2>Do you offer airport/railway staion shuttle service?</h2>
            <p>Any pickup or drop service to the closest airport, railway station, or bus station will be chargeable.</p>
            <p>All services should be booked in advance, and charges may vary based on location and specific services</p>
            <h2>What is your linen & damage Policy?</h2>
            <p>Please note that additional charges are applicable for linen stains and other cleaning or damages caused intentionally or unintentionally. Penalties vary depending on the extent and severity of the damage. These include, but are not limited to, cleaning, repair, or replacement charges for stained walls, vomit, blood and food stain on linens, and broken furniture and fittings. The exact cost will be determined on a case-by-case basis by the Manager in charge</p>
            <h2>Can I cancel my booking?</h2>
            <p>Yes, below is our cancellation policy</p>
            <p>Free cancellation up to 72 hours before the arrival date.</p>
            <p>Between 72 hours and 48 hours, a 50% cancellation charge will apply</p>
            <p>Between 48 hours and 24 hours, a 75% cancellation charge will be incurred.</p>
            <p>Within 24 hours, a 1-night retention charge will be applied.</p>
            <h2>Where can I make a reservation?</h2>
            <p>For room reservations at Samara Wellness Hotel, please email reservations@samarawellnesshotel.com or visit samarawellnesshotel.com. Direct bookers enjoy a best rate guarantee.</p>
        </div>
    </div>
  )
}

export default Faq