import './aboutstructure.css';

const AboutStructure = ({ data }) => {
    return (
        <>
            <div className='about_structure_container'>
                <div className="about_Mainhead"><p>{data.Mainhead}</p></div>
                <div className={`about_head ${data.classname}`}><p dangerouslySetInnerHTML={{ __html: data.head }} /></div>
                <div className="about_structure_wrap">
                    <div className="about_content_struture">
                        <div className="about_para"><p>{data.para}</p></div>
                    </div>
                    <div className="image_about"><img src={data.img} alt="" width='100%' height='100%' /></div>
                </div>
            </div>
        </>
    )
}

export default AboutStructure