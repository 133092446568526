import { Link } from 'react-router-dom';
import Banner from '../pages_images/Rooms_banner.png'
import './room.css';
import ch1 from '../../component/roomComponent/pics/chestnut (1).JPG';
import ol3 from '../../component/roomComponent/pics/olive (3).JPG';
import e3 from '../../component/roomComponent/pics/executive (3).JPG';
import s5 from '../../component/roomComponent/pics/standard (5).JPG';
import l3 from '../roompages/images/la1 (3).jpeg';


const Room = () => {
    return (
        <>
            <div className='banner'>
                <div><img src={Banner} alt="" width='100%' /></div>
            </div>
            <div className='room_container_wrap_bg'>
                <div className='room_container_part'>
                    <div className="room_part">
                        <div className="room_image"><img src={ch1} alt="" width='100%' height='100%' /></div>
                        <div className='room_content_rooms'>
                            <div className="room_p"><p>Chestnut Suite</p></div>
                            <div className='room_button'>
                                <hr className='line' />
                                <div className='room_button_p'><p><Link to='/chestnut'><span>&#9675;</span> BOOK NOW</Link></p> </div>
                            </div>
                        </div>
                    </div>
                    <div className="room_part">
                        <div className="room_image"><img src={ol3} alt="" width='100%' height='100%' /></div>
                        <div className='room_content_rooms'>
                            <div className="room_p"><p>Olive Suite</p></div>
                            <div className='room_button'>
                                <hr className='line' />
                                <div className='room_button_p'><p><Link to='/olive'> <span>&#9675;</span> BOOK NOW</Link></p></div>
                            </div>
                        </div>
                    </div>
                    <div className="room_part">
                        <div className="room_image"><img src={l3} alt="" width='100%' height='100%' /></div>
                        <div className='room_content_rooms'>
                            <div className="room_p"><p>Lilac Suite</p></div>
                            <div className='room_button'>
                                <div>
                                    <hr className='line' />
                                    <div className='room_button_p'><p><Link to='/lilac'><span>&#9675;</span> BOOK NOW</Link></p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="room_part">
                        <div className="room_image"><img src={e3} alt="" width='100%' height='100%' /></div>
                        <div className='room_content_rooms'>
                            <div className="room_p"><p>Executive Room</p></div>
                            <div className='room_button'>
                                <div>
                                    <hr className='line' />
                                    <div className='room_button_p'><p><Link to='/executive'><span>&#9675;</span> BOOK NOW</Link></p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="room_part">
                        <div className="room_image"><img src={s5} alt="" width='100%' height='100%' /></div>
                        <div className='room_content_rooms'>
                            <div className="room_p"><p>Standard Room</p></div>
                            <div className='room_button'>
                                <div>
                                    <hr className='line' />
                                    <div className='room_button_p'><p><Link to='/standard'><span>&#9675;</span> BOOK NOW</Link></p></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Room