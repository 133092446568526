import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './TermsCondtions.css'

function TermsCondtions() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='terms-conditions'>
        <div className='container'>
            <h1>Terms & Conditions</h1>
            <p>Welcome to the Samara~ A Wellness Hotel by Bharat Thakur website. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions of use. Please read these terms carefully before using our website.</p>
            <h2>1. Acceptance of Terms:</h2>
            <p>By accessing or using the Samara Wellness Hotel website, you agree to be bound by these Terms & Conditions, as well as all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</p>
            <h2>2. Disclaimer:</h2>
            <p>The materials on Samara Wellness Hotel's website are provided on an 'as is' basis. Samara Wellness Hotel makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
            <h2>3. Limitations:</h2>
            <p>In no event shall Samara Wellness Hotel or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Samara Wellness Hotel's website, even if Samara Wellness Hotel or a Samara Wellness Hotel authorized representative has been notified orally or in writing of the possibility of such damage.</p>
            <h2>4. Revisions and Errata:</h2>
            <p>The materials appearing on Samara Wellness Hotel's website could include technical, typographical, or photographic errors. Samara Wellness Hotel does not warrant that any of the materials on its website are accurate, complete, or current. Samara Wellness Hotel may make changes to the materials contained on its website at any time without notice.</p>
            <h2>5. Links:</h2>
            <p>Samara Wellness Hotel has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Samara Wellness Hotel of the site. Use of any such linked website is at the user's own risk.</p>
            <h2>6. Modifications:</h2>
            <p>Samara Wellness Hotel may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>
            <p>By using the Samara Wellness Hotel website, you agree to abide by these terms and conditions. </p>
        </div>
    </div>
  )
}

export default TermsCondtions