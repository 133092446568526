import './footer.css';
import { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import logo from '../../component/componentgallery/logo_white.png';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";
import { IoMailOpenOutline } from "react-icons/io5";
import { BiPhoneCall } from "react-icons/bi";
import { useUser } from '../../statemanagement/UserContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Footer = () => {
  const { user, logout } = useUser();
  console.log(user)
  const logoutfun = () => {
    logout();
  }
  const secrets = 'youraregood';
  const [formData, setFormData] = useState({
    user_email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const payloads = {
    iss: "ecommerce",
    aud: "yourname"
  };
  const headers = {
    "alg": 'HS256',
  }
  const encodeHeader = btoa(JSON.stringify(headers));
  const payloadString = btoa(JSON.stringify(payloads));
  const signature = CryptoJS.HmacSHA256(encodeHeader + "." + payloadString, secrets).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;

  const handleSubmit = async (e) => {
    if (formData.user_email !== '') {
      e.preventDefault();
      axios.post('https://cascrud.samarawellnesshotel.com/email.php', formData, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          console.log(response);
          toast('Wow,You have Successfully Subscribe a Newsletter', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })

        .catch((error) => {
          console.error('Error posting data:', error);
        });
    }
    else {
      toast.error('Enter the Email', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

  };
  return (
    <>
      <div className='footer'>
        <div className='footer_part1'>
          <div className='social_logo'>
            <div className='logo_footer'>
              <img src={logo} alt="" width='100%' />
            </div>
            <div className='social_media_footer'>
             <Link to='https://www.facebook.com/samarawellnesshotel' target="_blank">
              <div className='social_icon_footer'>
                <FaFacebookF />
              </div>
              </Link>
              <Link to='https://www.instagram.com/samarawellnesshotel?igsh=ODA1NTc5OTg5Nw==' target="_blank">
                <div className='social_icon_footer'>
                  <FaInstagram />
                </div>
              </Link>
              {/* <div className='social_icon_footer'>
                <FaXTwitter />
              </div> */}
            </div>
          </div>
          <div className='footer_address'>
            <div className='footer_head'><p>Information</p></div>
            <div className='address_container_details'>
              <div className='address_icon'><GrMapLocation /></div>
              <div className='address_details'><Link to='https://maps.app.goo.gl/3MpMbATwFGUhY8dy8' target='_blank'>House No. 354 Survey No. 120/2k & 130/2L Morjim, 403519 North Goa.</Link></div>
            </div>
            <div className='address_container_details'>
              <div className='address_icon'><IoMailOpenOutline /></div>
              <div className='address_details'><Link to='mailto:Samarawellnesshotel@gmail.com'>Samarawellnesshotel@gmail.com</Link></div>
            </div>
            <div className='address_container_details'>
              <div className='address_icon'><BiPhoneCall /></div>
              <div className='address_details'><Link to='tel:07385733341'>07385733341</Link></div>
            </div>
          </div>
          <div className='footer_address'>
            <div className='footer_head'><p>Explore</p></div>
            <div className='footer_link'><Link to='/wellness'><p>Wellness</p></Link></div>
            <div className='footer_link'><Link to='/event'><p>Events</p></Link></div>
            <div className='footer_link'><Link to='/dining'><p>Dining</p></Link></div>
            <div className='footer_link'><Link to='/gallery'><p>Galley</p></Link></div>
          </div>
          <div className='footer_address footer_sub_space'>
            <div className='footer_head'><p>Subscribe to our Newsletter !</p></div>
            <div className='footer_subscribe'>
              <div className='subscribe_input'><input type="text" placeholder='Email Address' value={formData.user_email} onChange={handleChange} name="user_email" /></div>
              <div className='subscribe_button' onClick={handleSubmit}><p>Subscribe</p></div>
            </div>
          </div>
        </div>
        <div className='footer_part2'>
          <div className='part2_details'><p>Welcome to Samara - Your Haven of Wellness & Sustainability... </p></div>
        </div>
        <hr className='line' />
        <div className='footer_part3'>
          <div className='copyright'><p>Copyright © 2023. All Rights Reserved</p></div>
          <div className='part3_links'>
            <div className='footer_link'><Link to='/faq'>FAQ</Link></div>
            <hr />
            <div className='footer_link'><Link to='/terms-of-use'>Terms of Use</Link></div>
            <hr />
            <div className='footer_link'><Link to='/privacy-policy'>Privacy Policy</Link></div>
            {
              user ? <><hr /><div className='footer_link' onClick={logoutfun}><p >Logout Account</p></div></> : ''
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer