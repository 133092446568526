import Banner from '../pages_images/bannerpic (2).png';
import pic1 from '../pages_images/dining (1).png';
import pic2 from '../pages_images/dining (2).png';
import pic3 from '../pages_images/CG_LOGO_final (1).png';
import AboutStructure from '../../structure/aboutstructure/AboutStructure';
import AboutStructure2 from '../../structure/aboutstructure/AboutStructure2';
import './dining.css';
const Dining = () => {
    const data={
        Mainhead:'',
        head:'',
        para:"Mystique Spice, a luxury restaurant perched atop the terrace of the Samara Hotel boasts an elaborate and beautifully curated menu. A menu that transforms the act of dining into an art form, with a focus on exquisite Indian specialties. Each dish on the menu is prepared with passion and precision, where every bite becomes a revelation of taste and texture. Mystique Spice believes that dining is a sacred experience to be celebrated and deeply felt. The restaurant invites patrons to savor the rich tapestry of Indian flavors against the backdrop of nature's grandeur, promising an unforgettable gastronomic journey.",
        img:pic1,
        classname:''
    }
    const data2={
        Mainhead:'',
        head:'',
        para:"Guests at Mystique Spice can enjoy breathtaking views of the sea and witness spectacular sunsets from the Sundowner deck while relishing the fresh sea breeze. The air filled with the delightful aroma of delicious cuisine, and the atmosphere infused with the unique vibe of Morjim beach, creates a truly unparalleled dining experience. The restaurant also features a central platform for our resident DJ, creating an ambiance that uplifts your mood with foot tapping rhythms and captivating melodies.",
        img:pic2
    }
    const data3={
        Mainhead:'',
        head:'',
        para:"Our delicacies at Mystique Spice are hand crafted with love, care, and precision, making every bite truly evoke a deep sense of enjoyment and fulfilment. We grid and make our own spice mixes thus giving each dish its own unique character and flavour. Our Chaat Gully, offers you the real taste of freshly made Chaat.",
        img:pic3,
        classname:''
    }
    return (
        <>
            <div>
                <div><img src={Banner} alt="" width='100%' /></div>
            </div>
            <div className='dining_container_wrap_bg'>
                <AboutStructure data={data}/>
                <AboutStructure2 data={data2}/>
                <AboutStructure data={data3}/>
            </div>
        </>
    )
}

export default Dining